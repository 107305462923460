import React from 'react';
import DOMPurify from 'dompurify'; // to sanitize
import PropTypes from 'prop-types';

const MarkdownRenderer = ({ source: markdownText }) => {
  const getMarkdownText = () => {
    if (typeof markdownText !== 'string') {
      return null;
    }
    const sanitizedMarkup = DOMPurify.sanitize(`<div>${markdownText}</div`);
    return { __html: sanitizedMarkup };
  };

  return <div dangerouslySetInnerHTML={getMarkdownText()} />;
};

MarkdownRenderer.propTypes = {
  source: PropTypes.shape(Object).isRequired,
};
export default MarkdownRenderer;
