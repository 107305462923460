import { get } from 'lodash';
import moment from 'moment';
import { MutationBuilder, QueryBuilder } from '../queryBuilder';
import { DEFAULT_ERROR, DATE_PICKER_FORMAT } from '../../utils/constants';
import {
  PARENT_ENQUIRY,
  FIND_CENTERS,
  CLEAR_CENTRES,
  CLEAR_TCC_CENTRES,
  DISPATCH_SCHEDULE_DATA,
  SELECTED_CENTERS,
  CANCEL_USER_ENQUIRY,
  FETCH_ENQUIRY_STATES,
  FETCH_USER_ENQUIRIES,
  STORE_SEARCH_DATA,
  FETCH_CHILDREN_BY_PARENT,
  FIND_ENROLLMENT_CENTERS,
  SET_EXPANDED_ENQUIRY_ID,
  FIND_CHILDREN,
} from './actionTypes';
import SkRequest from './BaseRequest';
import apolloUtils, { SkApolloRequest } from '../../utils/apolloUtils';
import {
  FIND_CHILDREN_BY_PARENT,
  FIND_NEAREST_CENTRES_BY_LAT_LNG,
  IS_EXISTING_PARENT,
  IS_EXISTING_CHILD,
  GET_ENROLMENT_DATE_OPTIONS,
  SEND_APP_DOWNLOAD_LINK,
  FIND_ALL_REGISTRATION_CHILD,
  FIND_ALL_ECDA_REGISTRATION_CHILD,
} from '../models/parentEnquiry';

const _mMutationEnquiryBuilder = new MutationBuilder();
const _mQueryBuilder = new QueryBuilder();

_mMutationEnquiryBuilder.setNewMutation('cancelEnquiry', {});

_mMutationEnquiryBuilder.setNewMutation('newEnquiry', {});

_mQueryBuilder.setNewQuery('findAllEnquiryFrontendState', {
  data: {
    label: 'string',
    fkEnquiryState: 'string',
    status: 'string',
    icon: 'string',
    enquiryFrontendStateProperties: {
      data: {
        type: 'string',
        label: 'string',
        action: 'string',
      },
    },
  },
});

_mQueryBuilder.setNewQuery('getEnquiryFrontendStates', {
  vacancyAvailable: 'string',
  currentFrontendState: {
    ID: 'number',
    label: 'string',
    code: 'string',
    icon: 'string',
    properties: {
      ID: 'number',
      label: 'string',
      action: 'string',
      type: 'string',
    },
  },
  listStates: {
    ID: 'number',
    label: 'string',
    code: 'string',
    icon: 'string',
    status: 'string',
    properties: {
      ID: 'number',
      label: 'string',
      action: 'string',
      type: 'string',
    },
    children: {
      ID: 'number',
      label: 'string',
      code: 'string',
      icon: 'string',
      status: 'string',
      properties: {
        ID: 'number',
        label: 'string',
        action: 'string',
        type: 'string',
      },
    },
  },
});

_mQueryBuilder.setNewQuery('findAllCentreByDistance', {
  totalCount: 'number',
  data: {
    ID: 'number',
    fkSchool: 'number',
    label: 'string',
    active: 'bool',
    availability: 'bool',
    createdAt: 'timestamp',
    updatedAt: 'timestamp',
    addresses: {
      totalCount: 'number',
      data: {
        ID: 'number',
        fkCentre: 'number',
        postcode: 'int',
        name: 'string',
        address: 'string',
        lat: 'float',
        lng: 'float',
        city: 'string',
        country: 'string',
        active: 'bool',
        createdAt: 'timestamp',
        updatedAt: 'timestamp',
      },
    },
  },
});

_mQueryBuilder.setNewQuery('findAllEnrollmentCentresForSchool', {
  totalCount: 'number',
  data: {
    ID: 'number',
    fkSchool: 'number',
    label: 'string',
    code: 'string',
    active: 'bool',
    availability: 'bool',
    createdAt: 'timestamp',
    updatedAt: 'timestamp',
    addresses: {
      totalCount: 'number',
      data: {
        ID: 'number',
        fkCentre: 'number',
        postcode: 'int',
        name: 'string',
        address: 'string',
        lat: 'float',
        lng: 'float',
        city: 'string',
        country: 'string',
        active: 'bool',
        createdAt: 'timestamp',
        updatedAt: 'timestamp',
      },
    },
  },
});

_mQueryBuilder.setNewQuery('findAllEnquiry', {
  data: {
    ID: 'number',
    fkCentre: 'number',
    fkUser: 'number',
    fkChild: 'number',
    firstname: 'string',
    lastname: 'string',
    fkEnquiryState: 'string',
    active: 'bool',
    childFirstname: 'string',
    childLastname: 'string',
    enrollmentPlan: 'datetime',
    isTransferEnquiry: 'bool',
    documentStatus: 'string',
    documentNotificationSent: 'bool',
    applicationSubmitted: 'bool',
    isTransferEnquiryReadyForEnrolment: 'bool',
    enrolmentStartDate: 'datetime',
    createdAt: 'datetime',
    program: {
      ID: 'number',
      label: 'string',
    },
    level: {
      ID: 'number',
      label: 'string',
    },
    centre: {
      ID: 'number',
      label: 'string',
      fkSchool: 'number',
      contactNumber: 'string',
      addresses: {
        data: {
          address: 'string',
          ID: 'number',
        },
      },
    },
    user: {
      email: 'string',
      mobilePhone: 'string',
    },
    admissionStatus: {
      ID: 'number',
      label: 'string',
      stateCode: 'string',
    },
    visits: {
      totalCount: 'number',
      data: {
        ID: 'number',
        status: 'string',
        visitTo: 'string',
        visitFrom: 'string',
        visitType: 'string',
        centre: {
          label: 'string',
        },
      },
    },
    enrolmentStep: 'string',
    skipCentreVisit: 'bool',
    skipTrial: 'bool',
    vacancyAvailable: 'bool',
    vacancyStatus: 'string',
  },
});

const dispatchEnquiry = (
  dispatch,
  inProgress = true,
  queryData = {},
  error = null
) => {
  dispatch({
    type: PARENT_ENQUIRY,
    key: 'enquiry',
    value: {
      inProgress,
      queryData,
      error,
    },
  });
};

const dispatchCenterSearch = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FIND_CENTERS,
    key: 'centerSearch',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchEnrollmentCenterSearch = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FIND_ENROLLMENT_CENTERS,
    key: 'enrollmentCenterSearch',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchEnquiryStates = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ENQUIRY_STATES,
    key: 'enquiryStates',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchCancelEnquery = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: CANCEL_USER_ENQUIRY,
    key: 'cancelEnquiry',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchUserEnqueries = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_USER_ENQUIRIES,
    key: 'userEnqueries',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchChildrenEnqueriesByParent = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CHILDREN_BY_PARENT,
    key: 'childrenEnqueriesByParent',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const dispatchScheduleData = (data, errors) => dispatch => {
  dispatch({
    type: DISPATCH_SCHEDULE_DATA,
    key: 'scheduleTimings',
    value: { data, errors },
  });
};

export const dispatchClearCenterSearch = (data = {}) => async dispatch => {
  dispatch({
    type: CLEAR_CENTRES,
    key: 'centerSearch',
    value: data,
  });
};

export const dispatchTccClearCenterSearch = (data = {}) => async dispatch => {
  dispatch({
    type: CLEAR_TCC_CENTRES,
    key: 'enrollmentCenterSearch',
    value: data,
  });
};

export const setSelectedPanelEnquiry = (data = {}) => dispatch => {
  dispatch({
    type: SET_EXPANDED_ENQUIRY_ID,
    key: 'applicationDetailPanel',
    value: data,
  });
};

const dispatchfindAllChildrenByParent = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FIND_CHILDREN,
    key: 'allChildrenByParent',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const cancelUserEnquiry = ID => async dispatch => {
  dispatchCancelEnquery(dispatch);

  const query = _mMutationEnquiryBuilder.getMutationAsString('cancelEnquiry', {
    ID,
  });

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchCancelEnquery(dispatch, false, {
        data: data.data,
      });
    } else {
      dispatchCancelEnquery(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchCancelEnquery(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const fetchUserEnquiries = (
  filter = {},
  isCancelledIncluded = false
) => async dispatch => {
  dispatchUserEnqueries(dispatch);

  const stateFilter = {};
  if (!isCancelledIncluded) {
    stateFilter.neq = 'cancelled';
  }
  _mQueryBuilder.enableResponseParams('findAllEnquiry');
  const query = _mQueryBuilder.getQueryAsString('findAllEnquiry', {
    filter: Object.assign(
      {
        active: true,
        fkEnquiryState: [stateFilter],
      },
      filter
    ),
    pagination: {
      sort: ['-createdAt'],
    },
  });

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchUserEnqueries(dispatch, false, {
        data: data.data,
      });
    } else {
      dispatchUserEnqueries(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchUserEnqueries(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const fetchChildrenBySchool = reqParam => async dispatch => {
  dispatchChildrenEnqueriesByParent(dispatch);
  _mQueryBuilder.enableResponseParams('findAllEnquiry', {
    data: {
      ID: 'number',
      fkUser: 'string',
      fkChild: 'string',
      childFirstname: 'string',
      childLastname: 'string',
      firstname: 'string',
      lastname: 'string',
    },
  });
  const query = _mQueryBuilder.getQueryAsString('findAllEnquiry', reqParam);

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchChildrenEnqueriesByParent(dispatch, false, {
        data: data.data.findAllEnquiry,
      });
    } else {
      dispatchChildrenEnqueriesByParent(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchChildrenEnqueriesByParent(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const findCentersSelected = (selected = []) => async dispatch => {
  dispatch({
    type: SELECTED_CENTERS,
    key: 'selectedCenters',
    value: selected,
  });
};

export const storeSearchData = (storedData = {}) => async dispatch => {
  dispatch({
    type: STORE_SEARCH_DATA,
    key: 'storedSearchData',
    value: storedData,
  });
};

export const findNearestCentres = variables => async dispatch => {
  dispatchEnrollmentCenterSearch(dispatch);

  try {
    const result = await SkApolloRequest({
      params: {
        query: FIND_NEAREST_CENTRES_BY_LAT_LNG,
        variables,
      },
      type: 'query',
    });

    if (
      !result.loading &&
      result.data &&
      result.data.findNearestCentresByLatLong
    ) {
      dispatchEnrollmentCenterSearch(dispatch, false, {
        data: result.data,
      });
    } else {
      dispatchEnrollmentCenterSearch(
        dispatch,
        false,
        null,
        get(result, 'data.error', DEFAULT_ERROR)
      );
    }

    return result.data;
  } catch (ex) {
    dispatchEnrollmentCenterSearch(dispatch, false, null, {
      error: ex.message,
    });
    throw ex;
  }
};

export const findCenters = (
  postalCode,
  distance,
  enrollmentDate,
  level,
  filter = {},
  pagination = {}
) => async dispatch => {
  dispatchCenterSearch(dispatch);

  const query = _mQueryBuilder.getQueryAsString('findAllCentreByDistance', {
    postalCode,
    radius: distance,
    enrollmentDate,
    level,
    filter,
    pagination,
  });

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchCenterSearch(dispatch, false, data.data);
    } else {
      dispatchCenterSearch(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchCenterSearch(dispatch, false, null, { error: ex.message });
  }
};

export const findEnrollmentCentres = reqData => async dispatch => {
  dispatchEnrollmentCenterSearch(dispatch);
  const query = _mQueryBuilder.getQueryAsString(
    'findAllEnrollmentCentresForSchool',
    reqData
  );
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchEnrollmentCenterSearch(dispatch, false, {
        data: data.data,
      });
    } else {
      dispatchEnrollmentCenterSearch(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchEnrollmentCenterSearch(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const fetchEnquiryStates = enquiryData => async dispatch => {
  dispatchEnquiryStates(dispatch, true, null);

  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getBulkQueryAsString({
          getEnquiryFrontendStates: enquiryData.map(item => ({
            IDEnquiry: item.ID,
          })),
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchEnquiryStates(dispatch, false, {
        data: data.data,
        enquiryData,
      });
    } else {
      dispatchEnquiryStates(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchEnquiryStates(dispatch, false, null, { error: ex.message });
    return null;
  }
};

export const newEnquiry = (
  school,
  email,
  countryCode,
  mobile,
  centerIds,
  enquiry
) => async dispatch => {
  dispatchEnquiry(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationEnquiryBuilder.getMutationAsString('newEnquiry', {
          schoolID: school,
          email,
          phoneNumberCountryCode: countryCode,
          phoneNumber: mobile,
          centreIDs: centerIds,
          enquiry,
        }),
      },
      method: 'POST',
    });
    const queryData = {
      data,
      email,
      mobile,
      centerIds,
      enquiry,
    };
    if (data.success) {
      dispatchEnquiry(dispatch, false, queryData);
    } else {
      dispatchEnquiry(dispatch, false, null, queryData.error);
    }
    return data;
  } catch (ex) {
    dispatchEnquiry(dispatch, false, null, { error: ex.message });
  }
};
export const findAllChildrenByParent = (
  childLevelAt = moment().startOf('month').format(DATE_PICKER_FORMAT)
) => async dispatch => {
  dispatchfindAllChildrenByParent(dispatch);
  const client = await apolloUtils();

  try {
    const result = await client.query({
      query: FIND_CHILDREN_BY_PARENT,
      variables: {
        childLevelAt,
      },
    });
    if (!result.loading && result.data && result.data.findAllChildrenByParent) {
      dispatchfindAllChildrenByParent(dispatch, false, result.data);
    } else {
      dispatchfindAllChildrenByParent(
        dispatch,
        false,
        null,
        get(result, 'data.error', DEFAULT_ERROR)
      );
    }

    return result.data;
  } catch (ex) {
    dispatchfindAllChildrenByParent(dispatch, false, null, {
      error: ex.message,
    });
    throw ex;
  }
};

export const findIsExistingParent = email => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        query: IS_EXISTING_PARENT,
        variables: {
          email,
        },
      },
      type: 'query',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const findIsExistingChild = birthCertificate => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        query: IS_EXISTING_CHILD,
        variables: {
          birthCertificate,
        },
      },
      type: 'query',
    });
    return data.data;
  } catch (ex) {
    return ex;
  }
};

export const getEnrolmentDateOptions = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ENROLMENT_DATE_OPTIONS,
        variables: {
          ...reqData,
        },
      },
      type: 'query',
    });
    return data.data;
  } catch (ex) {
    return ex;
  }
};

export const sendAppDownloadLink = reqData => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: SEND_APP_DOWNLOAD_LINK,
        variables: {
          mobile: reqData.mobileNumber,
          countryCode: reqData.countryCode,
        },
      },
      type: 'mutation',
    });
    return result;
  } catch (ex) {
    return ex;
  }
};

export const findAllRegistrationChild = filter => async () => {
  try {
    const res = await SkApolloRequest({
      params: {
        query: FIND_ALL_REGISTRATION_CHILD,
        variables: {
          filter,
        },
      },
      type: 'query',
    });
    return res.data;
  } catch (ex) {
    return ex;
  }
};

export const findAllECDARegistrationChild = filter => async () => {
  try {
    const res = await SkApolloRequest({
      params: {
        query: FIND_ALL_ECDA_REGISTRATION_CHILD,
        variables: {
          filter,
        },
      },
      type: 'query',
    });
    return res.data;
  } catch (ex) {
    return ex;
  }
};
