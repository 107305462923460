import {
  FETCH_DISCOUNT_GROUPS,
  FETCH_DISCOUNT_OPTIONS_CONFIG,
  FETCH_ALL_APPLICABLE_CHILD_DISCOUNTS,
  FETCH_ALL_CHILD_DISCOUNTS,
  ACTION_REMOVE_CHILD_DISCOUNT,
  ACTION_DEACTIVATE_CHILD_DISCOUNT,
  FETCH_ALL_APPLICABLE_ENQUIRY_DISCOUNTS,
  FETCH_ALL_CHILD_DISCOUNTS_REGISTRATION,
  FETCH_ALL_CHILD_DISCOUNTS_ECDA_REGISTRATION,
  FETCH_ALL_APPLICABLE_ENQUIRY_DISCOUNTS_ECDA,
} from './actionTypes';
import { SkApolloRequest } from '../../utils/apolloUtils';
import { parseGraphQLError } from '../../utils';
import {
  GET_ALL_DISCOUNT_GROUPS,
  ACTIVATE_DISCOUNT,
  DEACTIVATE_DISCOUNT,
  GET_OPTIONS_CONFIG_FOR_DISCOUNT,
  ADD_NEW_DISCOUNT_GROUP,
  FETCH_ALL_APPLICABLE_CHILD_DISCOUNTS_ITEMS,
  FIND_ALL_CHILD_DISCOUNTS,
  ADD_DISCOUNT_ITEM_TO_CHILD,
  REMOVE_CHILD_DISCOUNT,
  DEACTIVATE_CHILD_DISCOUNT,
  CHANGE_OBSELETE_DATE_CHILD_DISCOUNT,
  FETCH_ALL_APPLICABLE_ENQUIRY_DISCOUNTS_ITEMS,
  ADD_DISCOUNT_ITEM_TO_REGISTRATION,
  FIND_ALL_CHILD_DISCOUNTS_REGISTRATION,
  REMOVE_CHILD_DISCOUNT_FOR_REGISTRATION,
  DEACTIVATE_CHILD_DISCOUNT_FOR_REGISTRATION,
  FIND_ALL_CHILD_DISCOUNTS_ECDA_REGISTRATION,
  FETCH_ALL_APPLICABLE_ENQUIRY_DISCOUNTS_ITEMS_ECDA,
  ADD_DISCOUNT_ITEM_TO_ECDA_REGISTRATION,
  REMOVE_CHILD_DISCOUNT_FOR_ECDA_REGISTRATION,
  DEACTIVATE_CHILD_DISCOUNT_FOR_ECDA_REGISTRATION,
} from '../models/discountModels';

const dispatchGetAllApplicableChildDiscounts = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_APPLICABLE_CHILD_DISCOUNTS,
    key: 'allApplicableChildDiscounts',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchGetAllApplicableEnquiryDiscounts = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_APPLICABLE_ENQUIRY_DISCOUNTS,
    key: 'allApplicableRegistrationDiscounts',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchGetAllApplicableEnquiryDiscountsEcda = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_APPLICABLE_ENQUIRY_DISCOUNTS_ECDA,
    key: 'allApplicableEcdaRegistrationDiscounts',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchGetAllChildDiscounts = (
  dispatch,
  inProgress = true,
  data,
  error = null
) => {
  dispatch({
    type: FETCH_ALL_CHILD_DISCOUNTS,
    key: 'discount',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchGetAllChildDiscountsRegistration = (
  dispatch,
  inProgress = true,
  data,
  error = null
) => {
  dispatch({
    type: FETCH_ALL_CHILD_DISCOUNTS_REGISTRATION,
    key: 'discountRegistration',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchGetAllChildDiscountsEcdaRegistration = (
  dispatch,
  inProgress = true,
  data,
  error = null
) => {
  dispatch({
    type: FETCH_ALL_CHILD_DISCOUNTS_ECDA_REGISTRATION,
    key: 'discountEcdaRegistration',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchDiscountGroups = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_DISCOUNT_GROUPS,
    key: 'discountGroups',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchGetDiscountOptionConfig = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_DISCOUNT_OPTIONS_CONFIG,
    key: 'discountOptionConfig',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchRemoveChildDiscount = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: ACTION_REMOVE_CHILD_DISCOUNT,
    key: 'removeChildDiscoutConfig',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchDeactivateChildDiscount = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: ACTION_DEACTIVATE_CHILD_DISCOUNT,
    key: 'deactivateChildDiscoutConfig',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const addDiscountToChild = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADD_DISCOUNT_ITEM_TO_CHILD,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const addDiscountToRegistration = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADD_DISCOUNT_ITEM_TO_REGISTRATION,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const addDiscountToEcdaRegistration = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADD_DISCOUNT_ITEM_TO_ECDA_REGISTRATION,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getAllChildDiscounts = childID => async dispatch => {
  dispatchGetAllChildDiscounts(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_CHILD_DISCOUNTS,
        variables: { childID },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchGetAllChildDiscounts(dispatch, false, data.data);
    } else {
      dispatchGetAllChildDiscounts(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchGetAllChildDiscounts(dispatch, false, null, {
      error: 'something Error',
    });
    return parseGraphQLError(ex);
  }
};

export const getAllChildDiscountsRegistration = registrationChildID => async dispatch => {
  dispatchGetAllChildDiscountsRegistration(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_CHILD_DISCOUNTS_REGISTRATION,
        variables: { registrationChildID },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchGetAllChildDiscountsRegistration(dispatch, false, data.data);
    } else {
      dispatchGetAllChildDiscountsRegistration(
        dispatch,
        false,
        null,
        data.error
      );
    }
    return data;
  } catch (ex) {
    dispatchGetAllChildDiscountsRegistration(dispatch, false, null, {
      error: 'something Error',
    });
    return parseGraphQLError(ex);
  }
};

export const getAllChildDiscountsEcdaRegistration = registrationChildID => async dispatch => {
  dispatchGetAllChildDiscountsEcdaRegistration(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_CHILD_DISCOUNTS_ECDA_REGISTRATION,
        variables: { registrationChildID },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchGetAllChildDiscountsEcdaRegistration(dispatch, false, data.data);
    } else {
      dispatchGetAllChildDiscountsEcdaRegistration(
        dispatch,
        false,
        null,
        data.error
      );
    }
    return data;
  } catch (ex) {
    dispatchGetAllChildDiscountsEcdaRegistration(dispatch, false, null, {
      error: 'something Error',
    });
    return parseGraphQLError(ex);
  }
};

export const getAllApplicableChildDiscounts = childID => async dispatch => {
  dispatchGetAllApplicableChildDiscounts(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FETCH_ALL_APPLICABLE_CHILD_DISCOUNTS_ITEMS,
        variables: {
          childID,
        },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchGetAllApplicableChildDiscounts(dispatch, false, data.data);
    } else {
      dispatchGetAllApplicableChildDiscounts(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchGetAllApplicableChildDiscounts(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
    return parseGraphQLError(ex);
  }
};

export const getAllApplicableEnquiryDiscounts = registrationChildID => async dispatch => {
  dispatchGetAllApplicableEnquiryDiscounts(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FETCH_ALL_APPLICABLE_ENQUIRY_DISCOUNTS_ITEMS,
        variables: {
          registrationChildID,
        },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchGetAllApplicableEnquiryDiscounts(dispatch, false, data.data);
    } else {
      dispatchGetAllApplicableEnquiryDiscounts(
        dispatch,
        false,
        null,
        data.error
      );
    }
    return data;
  } catch (ex) {
    dispatchGetAllApplicableEnquiryDiscounts(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
    return parseGraphQLError(ex);
  }
};

export const getAllApplicableEnquiryDiscountsEcda = registrationChildID => async dispatch => {
  dispatchGetAllApplicableEnquiryDiscountsEcda(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FETCH_ALL_APPLICABLE_ENQUIRY_DISCOUNTS_ITEMS_ECDA,
        variables: {
          registrationChildID,
        },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchGetAllApplicableEnquiryDiscountsEcda(dispatch, false, data.data);
    } else {
      dispatchGetAllApplicableEnquiryDiscountsEcda(
        dispatch,
        false,
        null,
        data.error
      );
    }
    return data;
  } catch (ex) {
    dispatchGetAllApplicableEnquiryDiscountsEcda(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
    return parseGraphQLError(ex);
  }
};

export const fetchDiscountGroups = reqData => async dispatch => {
  dispatchFetchDiscountGroups(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ALL_DISCOUNT_GROUPS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchFetchDiscountGroups(dispatch, false, data.data);
    } else {
      dispatchFetchDiscountGroups(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchFetchDiscountGroups(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const activateDiscount = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ACTIVATE_DISCOUNT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const deactivateDiscount = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: DEACTIVATE_DISCOUNT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const addNewDiscountGroup = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADD_NEW_DISCOUNT_GROUP,
        variables: {
          discountItemGroupData: reqData,
        },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const fetchDiscountOptionConfig = schoolID => async dispatch => {
  dispatchGetDiscountOptionConfig(dispatch);

  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_OPTIONS_CONFIG_FOR_DISCOUNT,
        variables: {
          filter: {
            fkSchool: schoolID,
          },
        },
      },
      type: 'query',
    });
    if (result.success) {
      dispatchGetDiscountOptionConfig(dispatch, false, result.data);
    } else {
      dispatchGetDiscountOptionConfig(dispatch, false, null, result.error);
    }
  } catch (ex) {
    dispatchGetDiscountOptionConfig(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const removeChildDiscount = (
  childID,
  remarks,
  discountArrangementId
) => async dispatch => {
  dispatchRemoveChildDiscount(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: REMOVE_CHILD_DISCOUNT,
        variables: {
          childID,
          discountArrangementId,
          remarks,
        },
      },
      type: 'mutation',
    });
    if (result.success) {
      dispatchRemoveChildDiscount(dispatch, false, result.data);
    } else {
      dispatchRemoveChildDiscount(dispatch, false, null, result.error);
    }
    return result;
  } catch (ex) {
    dispatchRemoveChildDiscount(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const removeChildDiscountForRegistration = (
  registrationChildID,
  remarks,
  discountArrangementId
) => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: REMOVE_CHILD_DISCOUNT_FOR_REGISTRATION,
        variables: {
          registrationChildID,
          discountArrangementId,
          remarks,
        },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const removeChildDiscountForEcdaRegistration = (
  registrationChildID,
  remarks,
  discountArrangementId
) => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: REMOVE_CHILD_DISCOUNT_FOR_ECDA_REGISTRATION,
        variables: {
          registrationChildID,
          discountArrangementId,
          remarks,
        },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const deactivateChildDiscountForRegistration = (
  registrationChildID,
  remarks,
  discountArrangementId
) => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: DEACTIVATE_CHILD_DISCOUNT_FOR_REGISTRATION,
        variables: {
          registrationChildID,
          discountArrangementId,
          remarks,
        },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const deactivateChildDiscountForEcdaRegistration = (
  registrationChildID,
  remarks,
  discountArrangementId
) => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: DEACTIVATE_CHILD_DISCOUNT_FOR_ECDA_REGISTRATION,
        variables: {
          registrationChildID,
          discountArrangementId,
          remarks,
        },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const deactivateChildDiscount = (
  childID,
  remarks,
  discountArrangementId
) => async dispatch => {
  dispatchDeactivateChildDiscount(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: DEACTIVATE_CHILD_DISCOUNT,
        variables: {
          childID,
          remarks,
          discountArrangementId,
        },
      },
      type: 'mutation',
    });
    if (result.success) {
      dispatchDeactivateChildDiscount(dispatch, false, result.data);
    } else {
      dispatchDeactivateChildDiscount(dispatch, false, null, result.error);
    }
    return result;
  } catch (ex) {
    dispatchDeactivateChildDiscount(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const changeObsoleteDateChildDiscount = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CHANGE_OBSELETE_DATE_CHILD_DISCOUNT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};
