import gql from 'graphql-tag';
import { UPLOAD_INFO } from './commonFragments';

export const SAVE_ECDA_REG_GIRO_APPLICATION = gql`
  mutation saveEcdaRegGiroApplication(
    $IDEcdaRegChild: Int!
    $IDSchool: Int!
    $giroApplication: GiroApplication!
    $isSiblingCda: Boolean
  ) {
    saveEcdaRegGiroApplication(
      IDEcdaRegChild: $IDEcdaRegChild
      IDSchool: $IDSchool
      giroApplication: $giroApplication
      isSiblingCda: $isSiblingCda
    ) {
      ID
    }
  }
`;

export const GET_ID_DOCUMENT_TYPE_CONFIG_ECDA_GIRO_URL = gql`
  mutation GetEcdaGiroFormUploadURL(
    $IDBankAccount: Int!
    $IDDocumentTypeConfig: Int
    $IDEcdaRegChild: Int
    $IDChild: Int
    $IDEnquiry: Int
    $file: Upload!
  ) {
    GetEcdaGiroFormUploadURL(
      IDBankAccount: $IDBankAccount
      IDDocumentTypeConfig: $IDDocumentTypeConfig
      IDChild: $IDChild
      IDEcdaRegChild: $IDEcdaRegChild
      IDEnquiry: $IDEnquiry
      file: $file
    ) {
      ...uploadInfo
    }
  }
  ${UPLOAD_INFO}
`;

export const FIND_ALL_ECDA_REG_CHILD_REFUNDS = gql`
  query getEcdaRegistrationRefundChildBalance(
    $ecdaRegistrationChildID: Int!
    $ecdaRegistrationID: Int
    $ftsInput: String
    $filter: RefundChildBalanceFilter
    $pagination: Pagination
  ) {
    getEcdaRegistrationRefundChildBalance(
      ecdaRegistrationChildID: $ecdaRegistrationChildID
      ecdaRegistrationID: $ecdaRegistrationID
      ftsInput: $ftsInput
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        refundNo
        refundAt
        amount
        requestedAt
        refundMode
        status
        remark
        type
        cancellationReason
      }
    }
  }
`;

export const MARK_GIRO_AS_COMPLETED_ECDA = gql`
  mutation ($IDEcdaRegistration: Int!) {
    markGiroAsCompletedForEcdaRegistration(
      IDEcdaRegistration: $IDEcdaRegistration
    )
  }
`;

export const SKIP_GIRO_ECDA = gql`
  mutation skipGiroForEcdaRegistration($IDEcdaRegistration: Int!) {
    skipGiroForEcdaRegistration(IDEcdaRegistration: $IDEcdaRegistration)
  }
`;

export const TRANSFER_ECDA_REG_CHILD_BALANCE = gql`
  mutation transferEcdaRegistrationChildBalance(
    $dto: RegistrationChildBalanceTransferDto!
  ) {
    transferEcdaRegistrationChildBalance(dto: $dto)
  }
`;

export const GET_ECDA_REGISTRATION_CHILD_BALANCE_SHEET = gql`
  query getEcdaRegistrationChildBalanceSheet(
    $ecdaRegistrationChildId: Int!
    $IDCentre: Int!
  ) {
    getEcdaRegistrationChildBalanceSheet(
      ecdaRegistrationChildId: $ecdaRegistrationChildId
      centreId: $IDCentre
    ) {
      cdaCredit
      nonCdaCredit
      cdaOutstanding
      nonCdaOutstanding
    }
  }
`;

export const GET_ECDA_REGISTRATION_REFUND_CHILD_BALANCE = gql`
  query getEcdaRegistrationRefundChildBalance(
    $registrationChildID: Int!
    $registrationID: Int
    $ftsInput: String
    $filter: RefundChildBalanceFilter
    $pagination: Pagination
  ) {
    getEcdaRegistrationRefundChildBalance(
      ecdaRegistrationChildID: $registrationChildID
      ecdaRegistrationID: $registrationID
      ftsInput: $ftsInput
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        refundNo
        refundAt
        amount
        requestedAt
        refundMode
        status
        remark
        type
        cancellationReason
      }
    }
  }
`;
