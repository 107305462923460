import { get } from 'lodash';
import { SkApolloRequest } from '../../utils/apolloUtils';
import {
  FETCH_ADMISSIONS,
  FETCH_REGISTRATION_STATES,
  SEARCH_CHILD_IN_ADMISSIONS,
  FETCH_ADMISSIONS_WITH_FILTER,
  FETCH_REGISTRATIONS,
  FETCH_EXISTING_PARENT,
  FETCH_EXISTING_CHILD,
  FETCH_REGISTRATION_STATES_SUCCESS,
  FETCH_REGISTRATION_STATES_ERROR,
  FETCH_SUGGESTED_FEE_TIER,
  FETCH_ECDA_REGISTRATIONS,
  FETCH_ECDA_EXISTING_PARENT,
  FETCH_ECDA_EXISTING_CHILD,
} from './actionTypes';
import {
  SEARCH_ADMISSIONS,
  FIND_ALL_ADMISSION,
  GET_REGISTRATIONS,
  GET_EXISTING_PARENT,
  GET_EXISTING_CHILD,
  CREATE_REGISTRATION,
  EDIT_REGISTRATION,
  CANCEL_REGISTRATION,
  REINSTATE_CANCELLED_REGISTRATION,
  GET_REGISTRATIONS_FRONTEND_STATES,
  GET_EXISTING_PARENT_INFO,
  SUGGESTED_FEE_TIER,
  MERGE_PARENT_ACCOUNT,
  MERGE_REGISTRATION_PARENT_EMAIL,
  GET_ECDA_REGISTRATIONS,
  CREATE_REGISTRATION_ECDA_MVP,
  GET_ECDA_EXISTING_PARENT,
  GET_ECDA_EXISTING_CHILD,
  EDIT_REGISTRATION_ECDAMVP,
  CANCEL_ECDA_REGISTRATION,
  REINSTATE_CANCELLED_ECDA_REGISTRATION,
} from '../models/admissionModel';

const dispatchFetchAdmissions = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ADMISSIONS,
    key: 'admissions',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAdmissionsWithFilter = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ADMISSIONS_WITH_FILTER,
    key: 'admissionsWithFilter',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const registrationStatesLoading = () => ({
  type: FETCH_REGISTRATION_STATES,
  key: 'admissionsFilter',
  value: {
    inProgress: true,
  },
});

const registrationStatesSuccess = data => ({
  type: FETCH_REGISTRATION_STATES_SUCCESS,
  key: 'admissionsFilter',
  value: {
    inProgress: false,
    data,
  },
});

const registrationStatesError = error => ({
  type: FETCH_REGISTRATION_STATES_ERROR,
  key: 'admissionsFilter',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const dispatchAllRegistrations = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_REGISTRATIONS,
    key: 'registrations',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllECDARegistrations = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ECDA_REGISTRATIONS,
    key: 'ecdaRegistrations',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchExistingParent = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_EXISTING_PARENT,
    key: 'existingParent',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchECDAExistingParent = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ECDA_EXISTING_PARENT,
    key: 'existingParent',
    value: {
      inProgress,
      data,
      error,
    },
  });
};
const dispatchExistingChild = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_EXISTING_CHILD,
    key: 'existingChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchECDAExistingChild = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ECDA_EXISTING_CHILD,
    key: 'existingChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchSuggestedFeeTierForRegistrationChild = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_SUGGESTED_FEE_TIER,
    key: 'suggestedFeeTier',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const clearExistingChild = (
  inProgress = true,
  data = null,
  error = null
) => async dispatch => {
  dispatch({
    type: FETCH_EXISTING_CHILD,
    key: 'clearExistingChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchSearchChildInAdmissions = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: SEARCH_CHILD_IN_ADMISSIONS,
    key: 'searchChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const requestAdmissions = async (reqData, dispatch, dispatchFunc) => {
  dispatchFunc(dispatch);

  try {
    const result = await SkApolloRequest({
      params: {
        query: FIND_ALL_ADMISSION,
        variables: reqData,
      },
      type: 'query',
    });

    if (get(result, 'success')) {
      dispatchFunc(dispatch, false, result.data);
    } else {
      dispatchFunc(dispatch, false, null, result.error);
    }
    return result.data;
  } catch (ex) {
    dispatchFunc(dispatch, false, null, { error: ex.message });
    throw ex;
  }
};

export const fetchAdmissions = reqData => async dispatch =>
  requestAdmissions(reqData, dispatch, dispatchFetchAdmissions);

export const fetchAdmissionsWithFilter = reqData => async dispatch =>
  requestAdmissions(reqData, dispatch, dispatchFetchAdmissionsWithFilter);

export const fetchRegistrationStates = () => async dispatch => {
  dispatch(registrationStatesLoading());

  try {
    const res = await SkApolloRequest({
      params: {
        query: GET_REGISTRATIONS_FRONTEND_STATES,
      },
      type: 'query',
    });

    const data = get(res, 'data.getRegistrationFrontendStates', []);
    dispatch(registrationStatesSuccess(data));
  } catch (ex) {
    dispatch(registrationStatesError(ex.message));
  }
};

export const fetchRegistrations = reqData => async dispatch => {
  dispatchAllRegistrations(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_REGISTRATIONS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchAllRegistrations(dispatch, false, data.data);
    } else {
      dispatchAllRegistrations(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchAllRegistrations(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const searchChildInAdmissions = searchVal => async dispatch => {
  dispatchSearchChildInAdmissions(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: SEARCH_ADMISSIONS,
        variables: {
          q: searchVal,
        },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchSearchChildInAdmissions(dispatch, false, data.data);
    } else {
      dispatchSearchChildInAdmissions(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchSearchChildInAdmissions(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const fetchExistingParent = (nric, email) => async dispatch => {
  dispatchExistingParent(dispatch);
  const variables = {
    email,
  };
  if (nric) {
    variables.nric = nric;
  }
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_EXISTING_PARENT,
        variables,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchExistingParent(dispatch, false, data.data);
    } else {
      dispatchExistingParent(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchExistingParent(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const getECDAExistingParent = email => async dispatch => {
  dispatchECDAExistingParent(dispatch);
  const variables = {
    email,
  };
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ECDA_EXISTING_PARENT,
        variables,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchECDAExistingParent(dispatch, false, data.data);
    } else {
      dispatchECDAExistingParent(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchECDAExistingParent(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const fetchExistingParentInfo = (parentFilter, childId) => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_EXISTING_PARENT_INFO,
        variables: {
          parentFilter,
          childId,
        },
      },
      type: 'query',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const fetchExistingChild = (
  birthCertificate,
  dateOfBirth
) => async dispatch => {
  dispatchExistingChild(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_EXISTING_CHILD,
        variables: {
          birthCertificate,
          dateOfBirth,
        },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchExistingChild(dispatch, false, data.data);
    } else {
      dispatchExistingChild(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchExistingChild(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const fetchECDAExistingChild = (
  birthCertificate,
  dateOfBirth
) => async dispatch => {
  dispatchECDAExistingChild(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ECDA_EXISTING_CHILD,
        variables: {
          birthCertificate,
          dateOfBirth,
        },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchECDAExistingChild(dispatch, false, data.data);
    } else {
      dispatchECDAExistingChild(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchECDAExistingChild(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const getSuggestedFeeTierForRegistrationChild = reqData => async dispatch => {
  dispatchSuggestedFeeTierForRegistrationChild(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: SUGGESTED_FEE_TIER,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchSuggestedFeeTierForRegistrationChild(dispatch, false, data.data);
    } else {
      dispatchSuggestedFeeTierForRegistrationChild(
        dispatch,
        false,
        null,
        data.error
      );
    }
    return data;
  } catch (ex) {
    dispatchSuggestedFeeTierForRegistrationChild(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const createRegistration = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CREATE_REGISTRATION,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const createRegistrationECDAMVP = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CREATE_REGISTRATION_ECDA_MVP,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const editRegistration = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: EDIT_REGISTRATION,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const editRegistrationECDAMVP = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: EDIT_REGISTRATION_ECDAMVP,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const mergerRegistrationParentEmail = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: MERGE_REGISTRATION_PARENT_EMAIL,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const mergeParentAccount = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: MERGE_PARENT_ACCOUNT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const cancelRegistration = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CANCEL_REGISTRATION,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const reinstateCancelledRegistration = (
  registrationID,
  remarks
) => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: REINSTATE_CANCELLED_REGISTRATION,
        variables: { registrationID, remarks },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const fetchECDARegistrations = reqData => async dispatch => {
  dispatchAllECDARegistrations(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ECDA_REGISTRATIONS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchAllECDARegistrations(dispatch, false, data.data);
    } else {
      dispatchAllECDARegistrations(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchAllECDARegistrations(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const cancelEcdaRegistration = async reqData => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CANCEL_ECDA_REGISTRATION,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const reinstateEcdaRegistration = async reqData => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: REINSTATE_CANCELLED_ECDA_REGISTRATION,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export default fetchAdmissions;
